.PrimaryCTA {
  border-radius: 10px !important;
  border: none;
  background-color: #73589b !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #fff !important;
  text-transform: capitalize !important;
  &:hover {
    background-color: darken(#73589b, 5%) !important;
  }
}
.PrimaryCTA---ALT {
  border-radius: 10px !important;
  border: none;
  background-color: #6c46a01a !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #6c46a0 !important;
  text-transform: capitalize !important;
  &:hover {
    background-color: darken(#6c46a01a, 5%) !important;
  }
}
.PrimaryCTA.Mui-disabled {
  background-color: #73589b75 !important;
}
.PrimaryCTA {
  &:disabled {
    background-color: #73589b75 !important;
  }
}

.PrimaryCTA--Outlined {
  border-radius: 10px !important;
  background-color: #fff !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #73589b !important;
  border: 1px solid #73589b !important;
  text-transform: capitalize !important;
}
.PrimaryCTA--Outlined.Mui-disabled {
  background-color: #fbfbfb8f !important;
}

.GreenCTA {
  display: flex;
  height: 50px !important;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px !important;
  background-color: #9dea66 !important;
  color: #21231e !important;
  text-transform: capitalize !important;
}

.GreenCTA.Mui-disabled {
  background-color: rgb(157 234 102 / 26%) !important;
  color: #666666 !important;
}

.RedCTA {
  display: flex;
  height: 50px !important;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px !important;
  background: #dd263c !important;
  color: #fff !important;
  text-transform: capitalize !important;
}

.DarkCTA {
  background-color: #6c46a0 !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #fff !important;
  text-transform: capitalize !important;
  &:hover {
    background-color: darken(#111, 5%) !important;
  }
}

.PrimaryCTAExp {
  border-radius: 10px !important;
  border: none;
  background-color: #73589b !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #fff !important;
  text-transform: capitalize !important;
  &:hover {
    background-color: #5c467c !important;
  }
}
.PrimaryCTAExp.Mui-disabled {
  background-color: #73589b !important;
}
.PrimaryCTAExp {
  &:disabled {
    background-color: #73589b50 !important;
  }
}
